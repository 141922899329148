<template>
  <ul class="chat-list">
    <li
      v-for="(chat, index) in chats"
      :key="index"
      class="chat-item"
      @click="handleConversation(chat)"
    >
      <div class="chat-content">
        <div class="chat-avatar">
          <img
            :src="chat.user_profile_picture"
            :alt="chat.user_name"
            class="avatar-img"
          />
        </div>
        <div class="chat-details">
          <div class="chat-info">
            <h3 class="chat-name">{{ chat.user_name }}</h3>
            <p class="chat-time">{{ formatTime(chat.time) }}</p>
          </div>
          <p class="chat-message">
            {{ index === 0 && recentMessage ? recentMessage.message : chat.message }}
          </p>
        </div>
        <div class="chat-status">
          <span v-if="chat.badge" class="chat-badge">{{ chat.badge }}</span>
          <span v-else class="chat-read"><i class="fas fa-check"></i></span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      chats: []
    };
  },
  async mounted() {
    await this.fetchChats();
    console.log("mounted:...");
    if (this.chats.length > 0) {
      this.$store.commit('setConversationId', this.chats[0].id);
      console.log(this.chats[0].id);
    }
  },
  computed: {
    ...mapGetters(['recentMessage'])
  },
  methods: {
    async fetchChats() {
      try {
        const response = await axios.get('/api/get-conversation');
        this.chats = response.data.data.map(chat => ({
          id: chat.id,
          message: chat.message,
          time: chat.time,
          user_name: chat.user_name,
          user_profile_picture: chat.user_profile_picture,
          badge: chat.badge || null
        }));
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    },
    handleConversation(conversation) {
      console.log(conversation.id);
      this.$store.commit('setConversationId', conversation.id);
    },
    formatTime(time) {
      // You can implement a proper time formatting function here
      // For example, using date-fns or moment.js
      return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
  }
};
</script>

<style scoped>
.chat-list {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-item {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.chat-item:last-child {
  border-bottom: none;
}

.chat-item:hover {
  background-color: #f5f5f5;
}

.chat-content {
  display: flex;
  align-items: center;
}

.chat-avatar {
  margin-right: 15px;
}

.avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-details {
  flex-grow: 1;
}

.chat-info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 5px;
}

.chat-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.chat-time {
  font-size: 12px;
  color: #888;
  margin: 0;
}

.chat-message {
  font-size: 14px;
  color: #666;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.chat-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-badge {
  background-color: #ff4081;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
}

.chat-read {
  color: #4caf50;
  font-size: 14px;
}
</style>
