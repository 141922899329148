<template>
  <div class="row">
      <chat-list />
    <div class="col-md-6 col-lg-7 col-xl-7">
      <ul class="list-unstyled text-white">
       <message-list :username="username" :imgsrc="imgsrc" :userid="userid"/>
      </ul>
    </div>
  </div>
</template>

<script>
import ChatList from './ChatList.vue';
import MessageList from './MessageList.vue';

export default {
  data() {
    return {
      username: document.getElementById('app').dataset.username,
      imgsrc: document.getElementById('app').dataset.imgsrc,
      userid: document.getElementById('app').dataset.userid,
      role: document.getElementById('app').dataset.role, // Add role
    }
  },
  props: {
  },
  components: {
    ChatList,
    MessageList
  },
  mounted() {
      console.log(this.username);
  }
};
</script>

<style>
/* Add any global styles here */
</style>
    