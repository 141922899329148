<template>
  <div class="message-input-container">
    <textarea
      v-model="messageText"
      class="form-control"
      id="textAreaExample3"
      rows="2"
      placeholder="Type your message..."
      @keyup.enter="sendMessageOnEnter"
      :disabled="!canSendMessage"
    ></textarea>
    <button
      type="button"
      class="btn btn-primary btn-lg btn-rounded float-end mt-2"
      @click="sendMessage"
      :disabled="!canSendMessage"
    >Send</button>
    <p v-if="!canSendMessage && isTimedChatEnabled" class="text-muted">
      {{ messageInputStatus }}
    </p>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  props: ['username', 'imgsrc', 'conversationId', 'scheduledStartTime', 'endTime', 'currentTime', 'isTimedChatEnabled'],
  data() {
    return {
      messageText: ''
    };
  },
  computed: {
    canSendMessage() {
      if (!this.isTimedChatEnabled) return true;
      return this.currentTime >= this.scheduledStartTime && this.currentTime < this.endTime;
    },
    messageInputStatus() {
      if (!this.isTimedChatEnabled) return '';
      if (this.currentTime < this.scheduledStartTime) {
        return `Chat akan dibuka pada ${this.formatDate(this.scheduledStartTime)}`;
      } else if (this.currentTime >= this.endTime) {
        return 'Sesi chat telah berakhir';
      }
      return '';
    }
  },
  methods: {
    formatDate(date) {
      return format(date, 'yyyy-MM-dd HH:mm:ss');
    },
    sendMessage() {
      if (this.messageText.trim()) {
        const now = new Date();
        this.$emit('sendMessage', {
          imgsrc: this.imgsrc,
          name: this.username,
          time: now.toISOString(), // Send as ISO string
          message: this.messageText,
          conversation_id: this.conversationId
        });
        this.messageText = '';
      }
    },
    sendMessageOnEnter(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
  },
};
</script>

<style scoped>
.message-input-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.form-control {
  resize: none;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #e5e5e5; /* This remains as it's for the textarea */
}
.btn {
  background-color: #982176;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
}
.btn:hover {
  background-color: #7a1a5f; /* Darker shade for hover effect */
}
</style>