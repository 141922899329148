<template>
  <div class="message-list-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div v-if="remainingTime > 0 && isTimedChatEnabled" class="alert alert-info">
        Remaining time: {{ formatTime(remainingTime) }}
      </div>
      <button @click="toggleTimedChat" class="btn btn-secondary">
        {{ isTimedChatEnabled ? 'Disable' : 'Enable' }} Timed Chat
      </button>
    </div>
    <ul ref="messageList" class="message-list">
      <message-item
        v-for="(message, index) in messages"
        :key="message.id"
        :message="message"
        :userid="userid"
        :role="message.role"
      ></message-item>
    </ul>
    <message-input 
      @sendMessage="addMessage" 
      :username="username" 
      :imgsrc="imgsrc" 
      :conversationId="conversationId"
      :scheduledStartTime="scheduledStartTime"
      :endTime="endTime"
      :currentTime="currentTime"
      :isTimedChatEnabled="isTimedChatEnabled"
    ></message-input>
  </div>
</template>

<script>
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import MessageItem from './MessageItem.vue';
import MessageInput from './MessageInput.vue';
import { format, isToday, parseISO } from 'date-fns'; // Import date-fns for formatting

export default {
  props: ["username", "imgsrc", "userid"],
  components: {
    MessageItem,
    MessageInput
  },
  data() {
    return {
      messages: [],
      remainingTime: 0,
      status: '',
      scheduledStartTime: null,
      endTime: null,
      currentTime: new Date(),
      isTimedChatEnabled: true, // Default to true, can be toggled for testing
    };
  },
  methods: {
    async addMessage(newMessage) {
      try {
        const response = await axios.post('/api/send-messages', newMessage);
        // The new message will be pushed to the messages array via Pusher
      } catch (error) {
        console.error('Error adding message:', error);
      }
    },
    scrollToBottom() {
      const messageList = this.$refs.messageList;
      messageList.scrollTop = messageList.scrollHeight;
    },
    async fetchMessages() {
      try {
        const response = await axios.get(`/api/get-messages/${this.conversationId}`);
        this.messages = response.data.messages.map(message => ({
          ...message,
          time: this.formatMessageTime(message.time) // Use 'time' directly
        }));
        this.remainingTime = response.data.remainingTime;
        this.status = response.data.status;
        this.scheduledStartTime = new Date(response.data.scheduledStartTime);
        this.endTime = new Date(response.data.endTime);

        // Scroll to bottom after messages are fetched
        this.$nextTick(() => {
          this.scrollToBottom();
        });

        this.initializeEcho();
      } catch(error) {
        console.error('Error fetching messages:', error);
      }
    },
    formatTime(minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours}h ${mins}m`;
    },
    initializeEcho() {
      Pusher.logToConsole = true;
      window.Pusher = Pusher;

      const echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true,
        encrypted: true,
        authEndpoint: '/broadcasting/auth',
        auth: {
          headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
          }
        }
      });

      echo.private(`private-${this.conversationId}`)
        .listen('MessageSent', (event) => {
          console.log('MessageSent event received:', event);
          event.time = this.formatMessageTime(event.time);
          this.messages.push(event);
          this.$store.commit('setRecentMessage', event);
          // Scroll to bottom after a new message is added
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        })
        .subscribed(() => {
          console.log(`Subscribed to private-${this.conversationId}`);
        })
        .error((error) => {
          console.error('Error subscribing to channel:', error);
        });
    },
    formatMessageTime(time) {
      const date = parseISO(time);
      if (isToday(date)) {
        return format(date, 'HH:mm');
      } else {
        return format(date, 'yyyy-MM-dd HH:mm');
      }
    },
    toggleTimedChat() {
      this.isTimedChatEnabled = !this.isTimedChatEnabled;
    }
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    conversationId(newConversationId) {
      this.fetchMessages();
    }
  },
  computed: {
    conversationId() {
      return this.$store.getters.conversationId;
    }
  },
  mounted() {
    this.fetchMessages();
    // Update current time every second
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
    this.initializeEcho();
  }
};
</script>

<style scoped>
.message-list-container {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 0; /* Remove padding from container */
}

.message-list {
  height: 500px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  margin: 10px; /* Add margin to all sides */
}

.message-list::-webkit-scrollbar {
  width: 6px;
}

.message-list::-webkit-scrollbar-track {
  background: transparent;
}

.message-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.message-list::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Add these styles to match MessageInput.vue */
.alert {
  margin: 10px; /* Add margin to all sides */
}

message-input {
  margin-top: 0; /* Remove top margin */
}
</style>