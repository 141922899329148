import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    messages: [],
    recentMessage: null,
    conversationId: null
  },
  mutations: {
    setMessages(state, messages) {
      state.messages = messages;
    },
    addMessage(state, message) {
      state.messages.push(message);
    },
    setRecentMessage(state, message) {
      state.recentMessage = message;
    },
    setConversationId(state, conversationId) {
      state.conversationId = conversationId;
    }
  },
  actions: {
    fetchMessages({ commit }, conversationId) {
      return axios.get(`/api/get-messages/${conversationId}`)
        .then(response => {
          commit('setMessages', response.data.messages);
          commit('setRecentMessage', response.data.recentMessage);
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    },
    sendMessage({ commit }, message) {
      commit('addMessage', message);
    }
  },
  getters: {
    allMessages: state => state.messages,
    recentMessage: state => state.recentMessage,
    conversationId: state => state.conversationId,
  }
});
