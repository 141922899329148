<template>
  <li class="d-flex justify-content-between mb-4" :class="{'flex-row-reverse': message.sender_id == userid}">
    <img
      :src="message.imgsrc"
      alt="avatar"
      class="rounded-circle d-flex align-self-start shadow-1-strong"
      width="60"
    />
    <div class="card mask-custom w-100" :class="{'me-3': message.sender_id == userid, 'ms-3': message.sender_id != userid}">
      <div class="card-header d-flex justify-content-between p-3" 
           :class="{'bg-temancerita': role === 'counselor', 'bg-secondary': role !== 'counselor'}"
           style="border-bottom: 1px solid rgba(255,255,255,.3);">
        <p class="fw-bold mb-0 message-name">
          {{ message.name }}
          <span v-if="role === 'counselor'" class="badge bg-primary ms-2">Konselor</span>
          <span v-else class="badge bg-patient ms-2">Pasien</span>
        </p>
        <p class="text-light small mb-0"><i class="far fa-clock"></i> {{ message.time }}</p>
      </div>
      <div class="card-body" 
           :class="{'bg-temancerita': role === 'counselor', 'bg-secondary': role !== 'counselor'}">
        <p class="mb-0 message-text text-white">{{ message.message }}</p>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    message: Object,
    userid: String,
    role: String,
  }
};
</script>

<style scoped>
.d-flex.justify-content-between.mb-4 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.card-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.card-body {
  padding: 1rem;
}
.rounded-message {
  border-radius: 15px;
}
.bg-temancerita {
  background-color: #FB6F92 !important;
}
.bg-secondary {
  background-color: #CD378C !important;
}
.bg-patient {
  background-color: #19B591 !important;
}
.message-name {
  color: white !important;
}
.message-text {
  color: white !important;
}
.text-white {
  color: white !important;
}
</style>